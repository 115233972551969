<template>
  <v-col>
    <v-row>
      <v-col class="pt-0 col-auto">
        <span
          class="subtitle-2 primary--text cursor-pointer"
          @click="goToDashboard"
        >Custom Report</span>
      </v-col>

      <v-col class="pt-0 px-0 col-auto">
        <font-awesome-icon
          :icon="['fas', 'chevron-right']"
          class="gray--text"
          style="font-size: 9px; margin-bottom: 1px;"
        />
      </v-col>

      <v-col class="pt-0 col-auto">
        <span class="subtitle-2">{{ customReport.orgName }}</span>
      </v-col>
    </v-row>

    <v-row
      ref="dataviz"
      class="customReportPlaceholder ma-3"
      :style="currentStyleRules"
    >
      <!--    <tableau-viz-->
      <!--      id="tableauViz"-->
      <!--      ref="tableau"-->
      <!--      :src="currentSrc"-->
      <!--      :token="jwt"-->
      <!--      :device="device"-->
      <!--      :height="`${currentHeight}px`"-->
      <!--      :width="`${currentWidth}px`"-->
      <!--      :style="currentStyleRules"-->
      <!--      toolbar="top"-->
      <!--    />-->
    </v-row>
  </v-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import { TableauViz } from '@tableau/embedding-api'

const { mapState: mapAuthState } = createNamespacedHelpers('auth')
const { mapGetters: mapToolsGetters, mapState: mapToolsState } = createNamespacedHelpers('tools')

export default {
  name: 'ConnectedApp',
  props: {
    customReport: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      container: null,
      device: 'default',
      jwt: null,
      options: {},
      TABLEAU_CONFIG: {
        origin: 'https://10ay.online.tableau.com',
        paths: {
          metrics: '/#/site/oao/metrics',
          site: '/#/site/oao/views',
          views: '/t/oao/views',
        },
        query: ':embed=y&:showAppBanner=false&:display_count=n&:showVizHome=y&:origin=viz_share_link',
        scripts: {
          embedding: {
            loaded: false,
            path: '/javascripts/api/tableau.embedding.3.latest.js',
          },
          metric: {
            loaded: false,
            path: '/javascripts/api/tableau.metric.1.latest.min.js',
          },
        },
      },
      url: null,
      viz: null,
      vizScript: null,
      workbook: null,
    }
  },
  computed: {
    ...mapAuthState({
      credential: (state) => state.credential,
    }),
    ...mapToolsGetters([
      'getReportKey',
      'reportKey',
    ]),
    ...mapToolsState({
      reportId: (state) => state.reportId,
      reportName: (state) => state.reportName,
      searchResults: (state) => state.searchResults,
    }),
    worksheet() {
      return !this.workbook ? null : this.workbook.getActiveSheet()
    },
    currentSrc() {
      const { customReport, TABLEAU_CONFIG } = this
      const { origin, paths } = TABLEAU_CONFIG || {}
      const { metrics, site, views } = paths
      const { tableau: { path, tabs, type = 'views' } } = customReport || { tableau: {} }

      const urlMap = {
        metrics: () => `${origin}${metrics}/${path}`,
        site: () => `${origin}${site}/${path}`,
        views: () => `${origin}${views}/${path}/${tabs.default}`,
      }

      return urlMap[type]()
    },
    currentStyles() {
      return this.customReport.style || {}
    },
    currentWidth() {
      const { width = '300' } = this.currentStyles || {}
      return width
    },
    currentHeight() {
      const { height = '300' } = this.currentStyles || {}
      return height
    },
    currentStyleRules() {
      const { currentHeight, currentWidth } = this
      return `width: ${currentWidth}px; height: ${currentHeight}px;`
    },
  },
  watch: {
    customReport: {
      handler(newValue, oldValue) {
        if (newValue && (!oldValue || oldValue.href !== newValue.href)) {
          this.setup()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  beforeDestroy() {
    this.teardown()
  },
  methods: {
    teardown() {
      if (!this.viz || typeof this.viz.dispose !== 'function') {
        return false
      }

      return this.viz.dispose()
    },
    error({ message = 'Error Unknown' }) {
      const error = { message, status: 'error' }

      this.$store.commit('tools/snackbar', true)
      this.$store.commit('tools/snack', error)

      return { error }
    },
    onFirstInteractive() {
      if (!this.viz) return false

      this.workbook = this.viz.getWorkbook()
      this.activeSheet = this.workbook.getActiveSheet()

      return this.viz
    },
    async setToken() {
      const set = async () => {
        const token = await this.$store.dispatch('auth/getTableauToken')

        if (token && !token.error) {
          this.jwt = token.message
        } else {
          this.error(token)
        }

        return token
      }

      return this.jwt || set()
    },
    async setup() {
      await this.setToken()

      const { customReport } = this
      const { tableau } = customReport || { tableau: {} }
      const { path } = tableau || {}

      if (!path) {
        const { reportKey } = this
        const errorUnknownReport = () => (!tableau && {
          code: 404,
          layer: 'app/location',
          message: 'Unknown Custom Report',
          metadata: { reportKey },
        })
        const errorMisconfiguredReport = () => (!path && {
          code: 412,
          layer: 'app/data',
          message: 'Misconfigured Custom Report',
          metadata: { reportKey },
        })
        const error = errorUnknownReport() || errorMisconfiguredReport() || {
          code: 400,
          layer: 'app',
          message: 'Custom Reports Error',
          metadata: { reportKey },
        }

        this.error(error)

        return customReport
      }

      this.viz = new TableauViz()

      this.viz.token = this.jwt
      this.viz.src = this.currentSrc
      this.viz.device = this.device
      this.viz.height = `${this.currentHeight}px`
      this.viz.width = `${this.currentWidth}px`
      this.viz.style = this.currentStyleRules
      this.viz.toolbar = 'top'

      this.$refs.dataviz.appendChild(this.viz)

      return this
    },

    async findSelectedSearch(nav) {
      const { params, route: routeName } = nav
      const { searchResults } = this
      const { reportName, reportPath, reportId, subId } = params
      const reportKey = this.getReportKey({ reportName, reportPath, routeName })

      const searchEndpoint = searchResults.endpoints[reportKey]
      const searchCollection = await searchEndpoint.items.list

      return this.$store.dispatch('tools/findSelectedSearch', { reportId, reportName, searchCollection, subId })
    },
    async selectSearchItem(nav) {
      const selection = await this.findSelectedSearch(nav)

      await this.$store.dispatch('tools/selectSearchItem', selection)

      return this.$store.dispatch('tools/renderMainContent', this.$route)
    },
    goToDashboard() {
      const { reportName } = this.customReport
      const reportId = undefined
      const subId = undefined
      const nav = { params: { reportName, reportId, subId } }

      return this.selectSearchItem(nav)
    },
  },
}
</script>
